import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Styles from "./about.module.scss"
import StylesLayout from "../components/Layout.module.scss";
import H1 from "../components/Ui/H1";
import mascotBG from "../images/about/img_mascotAboutUs.png"
import H3 from "../components/Ui/H3";
import Ps from "../components/Ui/Ps";
import classNames from "classnames";
import Pb from "../components/Ui/Pb";

const AboutPage = () => (
    <Layout lightThem={false}>
        <SEO title="Full stack game development"
             description="WECAN distributed ledger game development team leverages emerging technology to create gaming
             applications utilizing EOSIo software."/>
        <div className={Styles.banner}>
            <H1>
                <span className={Styles.accent}>WECAN</span> is a gamedev team from Ukraine.
            </H1>
            <div className={Styles.image}>
                <img src={mascotBG} alt={"mascotBG"}/>
            </div>
        </div>
        <div className={StylesLayout.content}>
            <div className={Styles.missionText}>
                <H3>
                    The mission of the WECAN is to create an amazing gaming experience and allow players to play & earn
                    using the power of blockchain technology.
                </H3>
            </div>
            <div className={Styles.aboutTiles}>
                <div className={classNames(Styles.tile, Styles.widthTile)}>
                    <H3>WECAN is one of the few WAX Block Producers. </H3>
                    <Ps>Our team maintains its own WAX node. WECAN also takes a lot of effort to develop the whole WAX
                        ecosystem, to make it convenient for other developers and players.</Ps>
                </div>

                <div className={classNames(Styles.tile, Styles.thinTile)}>
                    <H3>Our projects.</H3>
                    <Ps>R-Planet, Pepper Stake, Prospectors, Tribal books,</Ps>
                </div>

                <div className={classNames(Styles.tile, Styles.leftHalfTile)}>
                    <H3>We implemented in these projects a lot of digital innovations.</H3>
                    <Ps>Staking systems, NFT, the migrator between EOS and WAX blockchain, Defi, DAO.</Ps>
                </div>

                <div className={classNames(Styles.tile, Styles.rightHalfTile)}>
                    <H3>TOP-3 game on WAX.</H3>
                    <Ps>Prospectors.io has been in the TOP-3 games on WAX, according to DappRadar, for the last two
                        years!</Ps>
                    <div className={Styles.numberContainer}>
                        <div className={Styles.number}>
                            <H3>
                                #3
                            </H3>
                        </div>
                        <Pb>
                            on WAX
                        </Pb>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default AboutPage;
